@import '@christies/base.css/index';

@import 'variables';
@import 'mixins';
@import 'font';
@import 'global';
@import 'icons';
@import 'padding';

:root {
  --z-index-base: 100;
}

.main * {
  font-family: map-get($font-families-new, tertiary);
}

.chr-heading-l-serif,
.chr-heading-m-serif,
.chr-heading-xl-serif,
.chr-font-serif {
  font-family: map-get($font-families-new, serif);
}
.chr-heading-l-serif,
.chr-heading-m-serif {
  font-weight: 300;
}
