@import '@christies/base.css/global';
@import '../../assets/styles/mixins';

.modal__body {
  gap: 1.5rem;
}

.text__link a {
  @include apply-typography('body-link', 'xs');
}
