$values: 8px, 16px, 24px, 32px, 40px, 48px;

@each $value in $values {
  $i: index(
    $list: $values,
    $value: $value
  );
  .pt-#{$i} {
    padding-top: $value;
  }
  .pb-#{$i} {
    padding-bottom: $value;
  }
  .pl-#{$i} {
    padding-left: $value;
  }
  .pr-#{$i} {
    padding-right: $value;
  }
}