@import '@christies/base.css/global';
@import 'variables';

.page {
  height: calc(100vh - 4rem);
  @include media-breakpoint-up(sm) {
    height: calc(100vh - 7.5rem);
  }
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: $color-white;
  padding-top: 10.75rem;
  &__bottom {
    position: absolute;
    bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media-breakpoint-up(sm) {
      bottom: 5rem;
    }
    &_button {
      text-decoration: none !important;
      padding: 0.75rem 1rem;
      border: 0.0625rem solid rgba($color-white, 0.1);
      margin-bottom: 1.5rem;
    }
  }
  &__number {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5625rem;
    letter-spacing: 0;
  }
  &__link {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0px;
    text-align: center;
    color: #dfdfdf;
    padding: 0;
    @include media-breakpoint-up(sm) {
      padding: 0 4rem;
    }
  }
  & h1 {
    margin: 2rem 0;
    font-family: map-get($font-families-new, serif);
    font-size: 3.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.5rem;
    letter-spacing: -0.001em;
  }
  & a {
    color: $color-white;
    text-decoration: underline;
    &:hover {
      color: $color-white;
    }
  }
}
