@import '@christies/base.css/global';

.button {
  text-align: center;
  padding: 1.063rem 2rem;
  width: 100%;
  background: $color-black;
  color: $color-white;
  border-radius: 0.125rem;
  pointer-events: all;
  position: relative;
  cursor: pointer;
  &:not(.link, .icon) {
    text-transform: uppercase;
    border-radius: 1.5rem;
    font-size: 0.75rem;
  }
  &:hover {
    opacity: 0.85;
  }
  &:disabled {
    pointer-events: none;
    opacity: 1;
    background: #ebebeb;
    color: #c2c2c2;
  }
  &.inverted {
    border: 0.0625rem solid $color-grey-alpha-10;
    background: $color-white;
    color: $color-black;
  }
  &.loading {
    color: #ebebeb;
    background: #ebebeb;
    &:after {
      -webkit-animation: spinAround 500ms infinite linear;
      animation: spinAround 500ms infinite linear;
      border: 3px solid $color-red-brand;
      border-radius: 1.25rem;
      border-left: 3px solid transparent;
      border-top: 3px solid transparent;
      content: '';
      display: block;
      height: 1.5em;
      width: 1.5em;
      left: calc(50% - (1.5em / 2));
      top: calc(50% - (1.5em / 2));
      position: absolute !important;
    }
  }
  &.link {
    width: auto;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    background: none;
    text-decoration: underline;
    color: $color-black;

    &:hover {
      text-decoration: none;
    }
  }
  &.icon {
    font-size: 0.75rem;
    font-weight: map-get($font-weights, medium);
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }
  }
  &__icon {
    vertical-align: middle;
    margin-right: 0.5rem;
    > svg {
      width: inherit;
      height: inherit;
    }
    &.xs {
      > svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
