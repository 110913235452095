html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

[data-whatintent='mouse'],
[data-whatintent='touch'] {
  *:focus + label {
    outline: none !important;
    &:before {
      outline: none !important;
    }
    &:after {
      outline: none !important;
    }
  }
}

body {
  color: $color-black;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-weight: 400;
  line-height: 1.34;
  letter-spacing: 0.01071em;
  background-color: $color-white;
  font-family: map-get($font-families-new, tertiary);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 400;
  line-height: 1.3;
  padding: 0;
  margin: 0;
}
h1,
.h1 {
  font-size: 2.5rem;
  line-height: 1.1;
  &.heading {
    font-size: 3rem;
  }
}
h2,
.h2 {
  font-size: 2rem;
}
h3,
.h3 {
  font-size: 1.5rem;
}
h4,
.h4 {
  font-size: 1rem;
}
h5,
.h5 {
  font-size: 0.75rem;
}
h6,
.h6 {
  font-size: 0.625rem;
}
p {
  @extend %chr-body;
}

input {
  border: 1px solid transparent;
}

a {
  color: $color-black;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

button {
  cursor: pointer;
  border: 1px solid transparent;
  background: none;
  padding: 0;
}

hr {
  margin: 0;
  border-top: 1px solid #dfdfdf;
}

.main {
  margin-bottom: 4.375rem;
  &.dark {
    background-color: $color-black;
    margin-bottom: 0;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__inner {
    @include width;
  }
}

.container-with-border {
  padding: 0;
  border: none;
  border-radius: 0.125rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 2.5rem;

  &:last-of-type {
    border: none;
  }

  @include media-breakpoint-up(sm) {
    padding: 2rem 1.5rem 2.5rem 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
}

.copy-link {
  @extend %chr-body-link;
}

@include media-high-contrast() {
  input[data-high-contrast='radio'] {
    &:checked + label {
      &:before {
        border: 12px solid $color-black !important;
      }
    }
  }

  button[data-high-contrast='toggle'] {
    + div[class*='dot'] {
      border: 12px solid $color-white !important;
    }
  }
}
