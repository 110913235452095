@import '@christies/base.css/global';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 0;
  max-height: 7.5rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding: 0.75rem;
  background-color: $color-white;
  @include media-breakpoint-up(sm) {
    padding: 0.75rem 3rem;
    justify-content: center;
  }
  &.loggedIn {
    justify-content: space-between;
    @include media-breakpoint-up(sm) {
      height: 6.7rem;
    }
  }
  &__account {
    padding: 0.48125rem;
    border-radius: 2.5rem;
    border: 1px solid #d1d1d1;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
  }
  .logo {
    touch-action: manipulation;
    position: relative;
    cursor: pointer;
    &.loggedOut {
      padding: 0.75rem 0;
    }
    .visuallyHidden {
      position: absolute !important;
      border: 0 !important;
      padding: 0 !important;
      width: 0.0625rem !important;
      height: 0.0625rem !important;
      overflow: hidden;
      clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    }
  }
}
