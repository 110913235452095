@import '@christies/base.css/global';
@import 'variables';

.overlay {
  align-items: center;
  background-color: rgba($color-black, 0.2);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.modal {
  background-color: $color-white;
  height: 100%;
  padding: 1.5rem;
  position: absolute;
  width: 100%;

  @include media-breakpoint-up(sm) {
    border-radius: 0.25rem;
    height: auto;
    max-height: 100%;
    overflow: auto;
    padding: 1.5rem 2.25rem 2.25rem 2.25rem;
    min-width: 34.5rem;
    max-width: 50vw;
  }
}

.header {
  display: flex;
  justify-content: flex-end;
}

.header__close {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg);

  > i {
    height: 1.875rem;
    width: 1.875rem;
  }
}

.content {
  margin-block-start: 0.75rem;
  text-align: center;

  h2 {
    font-family: map-get($font-families-new, serif);
    font-size: 1.5rem;
    line-height: 1.1;
  }

  > h2 + * {
    margin-block-start: 2em;
  }

  > * + * {
    margin-block-start: 1em;
  }
}

.footer {
  display: flex;
  justify-content: center;
  margin-block-start: 1.5rem;
}
