@import "@christies/base.css/global";

.disabled {
  :global {

    .chr-icon__fill--dark,
    .chr-icon__fill--light,
    .global__icon--fill {
      fill: $color-grey-alpha-40;
    }

    .chr-icon__stroke--dark,
    .chr-icon__stroke--light,
    .global__icon--stroke {
      stroke: $color-grey-alpha-40;
    }
  }
}