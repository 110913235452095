@mixin width {
  min-width: 21.9375rem;
  max-width: 22.5rem;
}

@mixin media-high-contrast() {
  @at-root .high-contrast {
    @content;
  }
}

@mixin media-high-contrast-windows($variation: 'active') {
  @media (-ms-high-contrast: $variation) {
    @content;
  }
}

@mixin fill($color: $color-black) {
  .global__icon--fill {
    fill: $color;
  }
}

@mixin stroke($color: $color-black) {
  .global__icon--stroke {
    stroke: $color;
  }
}

@include media-high-contrast() {
  .global__icon {
    &--fill {
      @include media-high-contrast-windows('black-on-white') {
        fill: $color-black !important;
      }

      @include media-high-contrast-windows('white-on-black') {
        fill: $color-white !important;
      }

      fill: currentColor !important;
    }

    &--stroke {
      @include media-high-contrast-windows('black-on-white') {
        stroke: $color-black !important;
      }

      @include media-high-contrast-windows('white-on-black') {
        stroke: $color-white !important;
      }

      stroke: currentColor !important;
    }
  }
}

// Workaround to override the anchor style as chr-body-link (copy-link class) doesn't exist in css from Footer component.
@mixin apply-typography($type, $breakpoint) {
  $type-map: map-get($typography, $type);
  $properties: map-get($type-map, $breakpoint);

  @each $property, $value in $properties {
    #{$property}: $value !important;
  }
}
