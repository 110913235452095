@import '@christies/base.css/global';

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.global__icon {
  margin: 0;

  & > svg {
    height: inherit;
    width: inherit;
  }

  &--logo {
    height: 1rem;
    width: 6rem;
    margin: 1rem 0;

    &.dark {
      @include fill($color-black);
    }
    @include media-breakpoint-up(sm) {
      height: 5.2em;
      width: 9rem;
      margin: 2rem 0;
    }
  }
  &--logoChinese {
    height: 1rem;
    width: 9rem;
    margin: 1rem 0;

    &.dark {
      @include fill($color-black);
    }
    @include media-breakpoint-up(sm) {
      height: 5.2em;
      width: 12rem;
      margin: 2rem 0;
    }
  }
  &--info {
    height: 1rem;
    width: 1rem;
    min-width: 1rem;

    &.dark {
      @include stroke($color-black);
      @include fill($color-black);
    }
    &.light {
      @include stroke($color-white);
      @include fill($color-white);
    }
    &.red {
      @include stroke($color-red-alert);
      @include fill($color-red-alert);
    }
  }

  &--infoLarge {
    height: 1.5rem;
    width: 1.5rem;

    &.dark {
      @include stroke($color-black);
      @include fill($color-black);
    }
    &.red {
      @include stroke($color-red-alert);
      @include fill($color-red-alert);
    }
  }

  &--diamond {
    height: 1.5rem;
    width: 1.5rem;

    &.dark {
      @include fill($color-black);
    }
  }

  &--user {
    height: 1.5rem;
    width: 1.5rem;

    &.dark {
      @include stroke($color-black);
    }
  }

  &--bid {
    height: 1.5rem;
    width: 1.5rem;

    &.dark {
      @include stroke($color-black);
    }
  }
  &--tick {
    height: 1.695rem;
    width: 0.5rem;

    &.light {
      @include stroke($color-white);
    }
  }
  &--tickBig {
    height: 1.3rem;
    width: 0.8125rem;

    &.dark {
      @include stroke($color-black);
    }

    &.green {
      @include stroke($color-positive-teal-dark);
    }
  }
  &--cross {
    height: 1.3rem;
    width: 0.625rem;

    &.red {
      @include stroke($color-red-alert);
    }
  }
  &--chevron {
    height: 1.5rem;
    width: 0.75rem;

    &.dark {
      @include stroke($color-black);
    }
  }
  &--404 {
    height: 12.5rem;
    width: 12.5rem;
    background-image: url('~assets/images/icons/404.svg');
    display: inline-block;
  }
  &--paperclip {
    height: 1rem;
    width: 1.063rem;

    &.dark {
      @include stroke($color-black);
    }
    &.light {
      @include stroke($color-white);
    }
  }
  &--loading {
    height: 1rem;
    width: 1rem;
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &.dark {
      @include stroke($color-black);
    }
  }
  &--verified {
    height: 1rem;
    width: 1rem;

    &.dark {
      @include fill($color-black);
    }
  }
  &--fileIndicator {
    height: 3.3125rem;
    width: 2.1125rem;

    &.dark {
      @include stroke($color-black);
    }
  }
  &--pdfIndicator {
    height: 3.3125rem;
    width: 2.1125rem;

    &.dark {
      @include stroke($color-black);
      @include fill($color-black);
    }
  }
  &--plus {
    height: 1rem;
    width: 1rem;

    &.dark {
      @include stroke($color-black);
      @include fill($color-black);
    }
  }
  &--minus {
    height: 1rem;
    width: 1rem;

    &.dark {
      @include stroke($color-black);
      @include fill($color-black);
    }
  }
}
