@font-face {
  font-family: 'Bembo';
  src:
    url('../fonts/bembo/c0e1eae2-ee5f-4f27-b289-66d82526f38f.woff2') format('woff2'),
    url('../fonts/bembo/7c89dcf7-0d32-43c8-992b-15e177072f6c.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Bembo';
  src:
    url('../fonts/bembo/83829768-cb1a-4e57-9d37-1c85618a33d5.woff2') format('woff2'),
    url('../fonts/bembo/29ec131d-60e3-4d2b-b609-d88b2299cda2.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Bembo';
  src:
    url('../fonts/bembo/0c0359e7-a973-4a74-aa67-50c672fb4f10.woff2') format('woff2'),
    url('../fonts/bembo/9aa33bd6-49d8-4f48-9e05-109da0b38662.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Bembo';
  src:
    url('../fonts/bembo/7b67b6c4-1c0e-4930-ac52-58fc7f2ed02d.woff2') format('woff2'),
    url('../fonts/bembo/a1ab1c1f-b2be-4b8b-aaae-71a7ea6debac.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'AtlasGrotesk';
  src:
    url('../fonts/atlas-grotesk/AtlasGrotesk-Light.eot'),
    url('../fonts/atlas-grotesk/AtlasGrotesk-Light.woff') format('woff'),
    url('../fonts/atlas-grotesk/AtlasGrotesk-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AtlasGrotesk';
  src:
    url('../fonts/atlas-grotesk/AtlasGrotesk-Regular.eot'),
    url('../fonts/atlas-grotesk/AtlasGrotesk-Regular.woff') format('woff'),
    url('../fonts/atlas-grotesk/AtlasGrotesk-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AtlasGrotesk';
  src:
    url('../fonts/atlas-grotesk/AtlasGrotesk-Medium.eot'),
    url('../fonts/atlas-grotesk/AtlasGrotesk-Medium.woff') format('woff'),
    url('../fonts/atlas-grotesk/AtlasGrotesk-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABCArizonaSans';
  src:
    url('../fonts/arizona/ABCArizonaSans-Thin.woff2') format('woff2'),
    url('../fonts/arizona/ABCArizonaSans-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABCArizonaSans';
  src:
    url('../fonts/arizona/ABCArizonaSans-Light.woff2') format('woff2'),
    url('../fonts/arizona/ABCArizonaSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABCArizonaSans';
  src:
    url('../fonts/arizona/ABCArizonaSans-Regular.woff2') format('woff2'),
    url('../fonts/arizona/ABCArizonaSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABCArizonaSans';
  src:
    url('../fonts/arizona/ABCArizonaSans-Medium.woff2') format('woff2'),
    url('../fonts/arizona/ABCArizonaSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABCArizonaSans';
  src:
    url('../fonts/arizona/ABCArizonaSans-Bold.woff2') format('woff2'),
    url('../fonts/arizona/ABCArizonaSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABCArizonaSerif';
  src:
    url('../fonts/arizona/ABCArizonaSerif-Thin.woff2') format('woff2'),
    url('../fonts/arizona/ABCArizonaSerif-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABCArizonaSerif';
  src:
    url('../fonts/arizona/ABCArizonaSerif-Light.woff2') format('woff2'),
    url('../fonts/arizona/ABCArizonaSerif-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABCArizonaSerif';
  src:
    url('../fonts/arizona/ABCArizonaSerif-Regular.woff2') format('woff2'),
    url('../fonts/arizona/ABCArizonaSerif-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABCArizonaSerif';
  src:
    url('../fonts/arizona/ABCArizonaSerif-Medium.woff2') format('woff2'),
    url('../fonts/arizona/ABCArizonaSerif-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABCArizonaSerif';
  src:
    url('../fonts/arizona/ABCArizonaSerif-Bold.woff2') format('woff2'),
    url('../fonts/arizona/ABCArizonaSerif-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
